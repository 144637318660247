import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="The Benefits of Custom Made Fiberglass Parts | Painted Rhino"
            description="Learn about the benefits of fiberglass parts and the processes involved. For your custom manufacturing needs, contact Painted Rhino today! Serving nationwide."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="The Benefits of Custom Made Fiberglass Parts"
            date="January 31, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     Many industries now use parts made from nontraditional materials such as custom made fiberglass. This option is both lightweight
                     and durable, making it the substance of choice for many enterprises. Expected to reach $21.4 billion by the year 2027, the global
                     fiberglass market is continually growing.
                  </p>

                  <h2>How Is Fiberglass Manufactured?</h2>
                  <p>
                     This material's production involves heating glass until it is molten and creating thin filaments used in various ways.
                     Manufacturing companies use a variety of methods to produce fiberglass parts.
                  </p>

                  <h2>Vacuum Assisted Resin Transfer Molding</h2>
                  <p>
                     This <a href="https://en.wikipedia.org/wiki/Vacuum_assisted_resin_transfer_molding#Process">closed mold process</a> creates a
                     composite material by incorporating a low viscosity resin with the fiberglass strands. The resin to fiber ratio produces sturdy,
                     high-performing aerospace-grade parts. This process is vital in manufacturing parts for infrastructure applications, wind energy
                     components and transportation industries.
                  </p>

                  <h2>Hand Lay-Up Technique</h2>
                  <p>
                     In this procedure, manufacturers place the fiber reinforcements by hand. The 
                     <a href="https://www.sciencedirect.com/topics/engineering/hand-lay-up#:~:text=1%20Hand%20lay%2Dup,matrix%20on%20the%20reinforcing%20material.">
                        hand lay-up method
                     </a>
                      is the oldest and most uncomplicated open molding process for forming composites. This approach typically involves the following
                     steps:
                  </p>

                  <ol>
                     <li>Placing dry fibers into the mold</li>
                     <li>Brushing or spraying an application of pigmented gel coating to the surface</li>
                     <li>Rolling the wet glass fiber layer until achieving the required thickness</li>
                     <li>Curing to set the composite without outside heat</li>
                  </ol>

                  <h2>Spray Lay-Up Process</h2>
                  <p>
                     In this method, manufacturers use chopper guns to chop and place strands into a mold automatically. Chopper guns are an
                     economical choice for making swimming pool parts, storage tanks and boat hulls. This technique includes the following actions:
                  </p>

                  <ol>
                     <li>Applying and curing gel and barrier layers</li>
                     <li>Adding the fiber resin composite to the mold</li>
                     <li>Chopping the filaments and spraying the material</li>
                     <li>Rolling or brushing to smooth the surface and remove trapped air bubbles</li>
                     <li>Curing, cooling and trimming</li>
                  </ol>

                  <h2>What Are the Advantages of Custom Made Fiberglass?</h2>
                  <p>
                     The unique qualities of fiber-reinforced plastic are beneficial for 
                     <a href="https://industrytoday.com/top-uses-of-fiberglass/">various industrial applications</a>.
                  </p>

                  <ul>
                     <li>
                        <b>Low maintenance</b>: This material requires little upkeep. It stands up to harsh environments, high temperatures and
                        corrosive conditions, unlike wood or metal.
                     </li>
                     <li>
                        <b>Non-conductive</b>: This substance's low thermal conductivity prevents product surfaces from becoming too hot, minimizing
                        possible injury when physical contact occurs. FRP does not interfere with radio transmissions, and features increased safety
                        as it is electrically non-conductive, removing potential shock hazards.
                     </li>
                     <li>
                        <b>Durable</b>: Lightweight yet extremely strong, FRP has a better strength-to-weight ratio than traditional materials like
                        wood, concrete or metal. It is equally tough as steel in most applications.
                     </li>
                     <li>
                        <b>Long-lasting</b>: This material is resistant to corrosives such as saltwater, rust and even acid, increasing its life span.
                     </li>
                     <li>
                        <b>Ease of installation</b>: Installing FRP requires fewer tools, equipment and machinery than other materials. It also calls
                        for less workforce, as its low weight makes it easier to handle. Installing elements like steel are often equipment intensive,
                        expensive and time-consuming.
                     </li>
                     <li>
                        <b>Low cost</b>: Shipping or moving FRP is considerably less expensive than other options due to its lightweight nature.
                        Decreased worker and tool requirements also reduce the expenses related to the project installation. The longevity of these
                        custom made fiberglass products provides significant cost savings, and the maintenance requirements are low, reducing costs
                        associated with upkeep.
                     </li>
                     <li>
                        <b>Options</b>: Available in many colors, there is no need to paint.
                     </li>
                  </ul>

                  <p>
                     At Painted Rhino, we specialize in custom fiberglass, composite materials, FRP and more. 
                     <a href="https://www.paintedrhino.com/">Contact our family-owned company</a> for quality service regarding your design and
                     manufacturing needs.
                  </p>

                  <p>
                     <b>Sources:</b>
                     <br />
                     <a href="https://www.sciencedirect.com/topics/engineering/hand-lay-up#:~:text=1%20Hand%20lay%2Dup,matrix%20on%20the%20reinforcing%20material">
                        https://www.sciencedirect.com/topics/engineering/hand-lay-up#:~:text=1%20Hand%20lay%2Dup,matrix%20on%20the%20reinforcing%20material
                     </a>
                     .
                  </p>

                  <p>
                     <a href="https://www.thoughtco.com/what-is-fiberglass-or-glass-fiber-820469">
                        https://www.thoughtco.com/what-is-fiberglass-or-glass-fiber-820469
                     </a>
                  </p>

                  <p>
                     <a href="https://www.reportlinker.com/p05798567/Global-Fiberglass-Industry.html?utm_source=GNW">
                        https://www.reportlinker.com/p05798567/Global-Fiberglass-Industry.html?utm_source=GNW
                     </a>
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/benefits-custom-fiberglass-parts.jpeg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
